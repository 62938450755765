import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import breakpoints from '../utils/breakpoints';
import { BsFacebook, BsInstagram } from 'react-icons/bs';

const StyledFooter = styled.footer`
  background-color: #231f20;
  padding-top: 4rem;
  padding-bottom: 1rem;
`;

const Container = styled.div`
  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    max-width: 1280px;
  }
`;

const CopyrightContainer = styled.div`
  padding: 15px 0.75rem;
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }

  p {
    color: #fff;
    font-size: 0.625rem;
    margin: 0;
  }
`;

const FooterSection = styled.div`
  width: 100%;
  padding: 0 0.75rem;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;

  @media (min-width: ${breakpoints.md}) {
    width: 25%;
  }
`;

const FooterContent = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0px 0px 1.625rem;
  }

  li {
    line-height: 1.5;
    margin-top: 0.375rem;

    span {
      font-weight: 500;
      color: #fff;
      font-size: 0.8125rem;
    }
  }

  a {
    font-size: 0.8125rem;
    line-height: 16px;
    color: #fff;
    text-decoration: none;
  }

  p {
    font-size: 0.8125rem;
    line-height: 1.5;
    color: #fff;
    margin: 0;
    padding: 0;
  }
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterSection>
        <FooterContent>
          <ul>
            <li>
              <Link to="/schedule">Schedule</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="https://cruzcmbt.com/shieldsystems" target="_blank">
                Shop
              </Link>
            </li>
          </ul>
        </FooterContent>
      </FooterSection>
      <FooterSection>
        <FooterContent>
          <ul>
            <li>
              <span>Location:</span>
              <p>
                Shield Systems Academy
                <br />
                8651 Middlebrook Pike
                <br />
                Knoxville, TN 37923
              </p>
            </li>
          </ul>
        </FooterContent>
      </FooterSection>
      <FooterSection>
        <div className="flex items-center">
          <a
            href="https://www.instagram.com/shieldsystemsacademy/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram Social Media Link"
          >
            <BsInstagram className="text-3xl mr-4 fill-white hover:fill-red-500" />
          </a>
          <a
            href="https://www.facebook.com/shieldsystemsmma/"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook Social Media Link"
          >
            <BsFacebook className="text-3xl fill-white hover:fill-red-500" />
          </a>
        </div>
      </FooterSection>
      <CopyrightContainer>
        <p>
          &copy; {new Date().getFullYear()}
          {` `}
          <a href="https://shieldsystemsacademy.com/" rel="license">
            Shield Systems Academy
          </a>
          {` `}
          All Rights Reserved.
        </p>
      </CopyrightContainer>
    </Container>
  </StyledFooter>
);

export default Footer;
